define("liquid-fire/components/liquid-spacer", ["exports", "@ember/component", "liquid-fire/components/liquid-measured", "liquid-fire/mixins/growable", "liquid-fire/templates/components/liquid-spacer", "jquery"], function (_exports, _component, _liquidMeasured, _growable, _liquidSpacer, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_growable.default, {
    layout: _liquidSpacer.default,
    enabled: true,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var elt = (0, _jquery.default)(this.element);
      var child = elt.find('> div');
      var measurements = this.myMeasurements((0, _liquidMeasured.measure)(child));
      this.element.style.overflow = 'hidden';

      if (this.growWidth) {
        elt.outerWidth(measurements.width);
      }

      if (this.growHeight) {
        elt.outerHeight(measurements.height);
      }
    },
    sizeChanged: function sizeChanged(measurements) {
      if (!this.enabled) {
        return;
      }

      if (!this.element) {
        return;
      }

      var want = this.myMeasurements(measurements);
      var elt = (0, _jquery.default)(this.element);
      var have = (0, _liquidMeasured.measure)(elt);
      this.animateGrowth(elt, have, want);
    },
    // given our child's outerWidth & outerHeight, figure out what our
    // outerWidth & outerHeight should be.
    myMeasurements: function myMeasurements(childMeasurements) {
      var elt = (0, _jquery.default)(this.element);
      return {
        width: childMeasurements.width + sumCSS(elt, padding('width')) + sumCSS(elt, border('width')),
        height: childMeasurements.height + sumCSS(elt, padding('height')) + sumCSS(elt, border('height'))
      };
    }
  });

  _exports.default = _default;

  function sides(dimension) {
    return dimension === 'width' ? ['Left', 'Right'] : ['Top', 'Bottom'];
  }

  function padding(dimension) {
    var s = sides(dimension);
    return ['padding' + s[0], 'padding' + s[1]];
  }

  function border(dimension) {
    var s = sides(dimension);
    return ['border' + s[0] + 'Width', 'border' + s[1] + 'Width'];
  }

  function sumCSS(elt, fields) {
    var accum = 0;

    for (var i = 0; i < fields.length; i++) {
      var num = parseFloat(elt.css(fields[i]), 10);

      if (!isNaN(num)) {
        accum += num;
      }
    }

    return accum;
  }
});