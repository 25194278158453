define("ember-tinymce/components/tinymce-editor", ["exports", "@ember/component", "@ember/object", "@ember/polyfills", "@ember/runloop"], function (_exports, _component, _object, _polyfills, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global tinymce: true */
  var defaultOptions = {};

  var _default = _component.default.extend({
    classNames: ['tinymce-editor'],
    tagName: 'textarea',
    tinyEvents: 'change keyup keydown keypress mousedown',
    changeDebounce: 10,
    // State
    options: defaultOptions,
    // editor stores the TinyMCE instance.
    editor: null,
    // optionsChanged observes changes to the TinyMCE configuration in order to
    // dynamically reload
    optionsChanged: (0, _object.observer)('options', function () {
      this.initTiny();
    }),
    // valueChanged watches value in order to push changes into TinyMCE
    valueChanged: (0, _object.observer)('value', function () {
      var _this$getProperties = this.getProperties('editor', 'value'),
          editor = _this$getProperties.editor,
          value = _this$getProperties.value;

      if (editor && editor.getContent() !== value) {
        editor.setContent(value || '');
      }
    }),
    // onValueChanged enables developers to supply a callback which is called when
    // the editor's content has changed.
    onValueChanged: function onValueChanged(value) {
      this.set('value', value);
    },
    // contentChanged detects whether the editor's content has changed.
    contentChanged: function contentChanged(editor) {
      var content = editor.getContent();

      if (editor.isDirty() && content !== this.get('value')) {
        this.onValueChanged(editor.getContent());
        editor.setDirty(true);
      }
    },
    // debounceContentChanged attempts to stop needless computation cycles, for
    // example, when holding down a key press.
    debounceContentChanged: function debounceContentChanged(editor, time) {
      (0, _runloop.debounce)(this, this.contentChanged, editor, time);
    },
    // setEvents binds events we want to listen to into TinyMCE.
    setEvents: (0, _object.observer)('editor', function () {
      var _this$getProperties2 = this.getProperties('changeDebounce', 'editor'),
          changeDebounce = _this$getProperties2.changeDebounce,
          editor = _this$getProperties2.editor;

      if (!editor) {
        return;
      }

      editor.on(this.tinyEvents, (0, _runloop.bind)(this, this.debounceContentChanged, editor, changeDebounce));
    }),
    // initTiny initializes a new TinyMCE editor, configured with the provided
    // options.
    initTiny: function initTiny() {
      var _this = this;

      var _this$getProperties3 = this.getProperties('options', 'editor'),
          options = _this$getProperties3.options,
          editor = _this$getProperties3.editor;

      if (!options) {
        // don't attempt to init if options provided are null.
        return;
      }

      var initFunction = function initFunction(editor) {
        _this.set('editor', editor);

        _this.get('editor').setContent(_this.get('value') || ''); //Set content with default text

      };

      var customOptions = {
        selector: "#".concat(this.get('elementId')),
        init_instance_callback: (0, _runloop.bind)(this, initFunction)
      };

      if (editor) {
        editor.setContent('');
        editor.destroy();
        this.set('editor', null);
      }

      (0, _runloop.later)(function () {
        if (typeof tinymce === 'undefined') {
          return;
        }

        tinymce.init((0, _polyfills.assign)({}, options, customOptions));
      }, 10);
    },
    // Lifecycle hooks
    didInsertElement: function didInsertElement() {
      this.initTiny();
    },
    willDestroyElement: function willDestroyElement() {
      var editor = this.get('editor');

      if (editor) {
        editor.off(this.tinyEvents);
        editor.destroy();
      }
    }
  });

  _exports.default = _default;
});