define("ember-modal-dialog/templates/components/in-place-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class={{concat
      this.containerClassNamesString
      ' '
      this.attachmentClass
      ' '
      this.containerClass
    }}
    ...attributes
  >
    {{yield}}
  </div>
  */
  {
    "id": "is2Nl/Fz",
    "block": "[[[11,0],[16,0,[28,[37,0],[[30,0,[\"containerClassNamesString\"]],\" \",[30,0,[\"attachmentClass\"]],\" \",[30,0,[\"containerClass\"]]],null]],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"concat\",\"yield\"]]",
    "moduleName": "ember-modal-dialog/templates/components/in-place-dialog.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});