define("ember-swagger-ui/components/swagger-ui", ["exports", "@ember/component", "swagger-ui"], function (_exports, _component, _swaggerUi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SwaggerUIBundle = _swaggerUi.default.SwaggerUIBundle;

  var _default = _component.default.extend({
    classNames: ['swagger-ui', 'component-swagger-ui'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var config = this.config || {
        deepLinking: false
      };
      var merged = Object.assign({}, config);

      if (!merged.dom_id) {
        merged.dom_id = "#".concat(this.elementId);
      }

      SwaggerUIBundle(merged);
    }
  });

  _exports.default = _default;
});