define("@ember/-internals/views/lib/views/states/in_dom", ["exports", "@ember/-internals/utils", "@ember/debug", "@ember/error", "@ember/-internals/views/lib/views/states/has_element"], function (_exports, _utils, _debug, _error, _has_element) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inDOM = Object.assign(Object.assign({}, _has_element.default), {
    enter: function enter(view) {
      // Register the view for event handling. This hash is used by
      // Ember.EventDispatcher to dispatch incoming events.
      view.renderer.register(view);

      if (false
      /* DEBUG */
      ) {
        var _elementId3 = view.elementId;
        (false && !(_utils.teardownMandatorySetter) && (0, _debug.assert)('[BUG] Expected teardownMandatorySetter to be set in DEBUG mode', teardownMandatorySetter));
        (0, _utils.teardownMandatorySetter)(view, 'elementId');
        Object.defineProperty(view, 'elementId', {
          configurable: true,
          enumerable: true,
          get: function get() {
            return _elementId3;
          },
          set: function set(value) {
            if (value !== _elementId3) {
              throw new _error.default("Changing a view's elementId after creation is not allowed");
            }
          }
        });
      }
    }
  });

  var _default = Object.freeze(inDOM);

  _exports.default = _default;
});